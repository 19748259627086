<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="50%"
    >
      <div>
        <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
          <el-row :gutter="24">
            <el-form-item label="类型" label-width="80px" prop="type">
              <el-radio-group v-model="addEditForm.type">
                <el-radio label="1">基础</el-radio>
                <el-radio label="2">扩展</el-radio>
                <el-radio label="2">增值</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="24">
            <el-form-item label="名称" label-width="80px" prop="name">
              <el-input v-model="addEditForm.name" width="30px"></el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="24">
            <el-form-item label="地址" label-width="80px" prop="url">
              <el-input v-model="addEditForm.url" width="30px"></el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="24">
            <el-form-item label="图标" label-width="80px" prop="icon">
              <el-input v-model="addEditForm.icon" width="30px"></el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="24">
            <el-form-item label="说明" label-width="80px" prop="explana">
              <el-input v-model="addEditForm.explana" width="30px" />
            </el-form-item>
          </el-row>
          <el-row :gutter="24">
            <el-form-item label="介绍" label-width="80px" prop="introduce">
              <el-input v-model="addEditForm.introduce" width="30px" />
            </el-form-item>
          </el-row>
          <!-- <el-row :gutter="24">
            <el-form-item label="定价" label-width="80px" prop="pricing">
              <el-input-number v-model="addEditForm.pricing" width="30px" />
            </el-form-item>
          </el-row> -->
          <el-row :gutter="24">
            <el-col :md="12" :sm="12">
              <el-form-item label="规格" label-width="80px" prop="specs">
                <el-radio-group v-model="addEditForm.specs" @change="specsButton">
                  <el-radio :label="1">单规格</el-radio>
                  <el-radio :label="2">多规格</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12">
              <el-form-item label="状态" label-width="80px" prop="status">
                <el-radio-group v-model="addEditForm.status">
                  <el-radio label="1">正常</el-radio>
                  <el-radio label="2">失效</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-button v-if="!addDataShow" @click="addDataListButton">新增</el-button>
          <el-table :data="dataColumnList">
            <el-table-column label="序号" align="center" width="65">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click.native.prevent="deleteRow(scope.$index, dataColumnList)"
                  v-if="!addDataShow"
                >移除</el-button>
              </template>
            </el-table-column>

            <el-table-column label="产品" align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.editShow">
                  <el-form :model="scope.row">
                    <el-select
                      v-model="scope.row.productValueCodeList"
                      filterable
                      placeholder="请选择"
                      style="width:70%"
                      value-key="uniqueCode"
                      @change="
                      selectDisposeMonth(
                        scope.$index,
                        dataColumnList,
                        scope.row.productValueCodeList
                      )
                    "
                    >
                      <el-option
                        v-for="item in productCodeList"
                        :key="item.uniqueCode"
                        :label="item.allName"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form>
                </template>
                <template v-else>
                  <span>{{scope.row.commodityName}}</span>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="时间" align="center" prop="times">
              <template slot-scope="scope">
                <template>
                  <el-input-number class="edit-input" v-model="scope.row.times" placeholder="数量" />
                </template>
              </template>
            </el-table-column>

            <el-table-column label="数量" align="center" prop="placingQuantity">
              <template slot-scope="scope">
                <template>
                  <el-input-number
                    class="edit-input"
                    v-model="scope.row.placingQuantity"
                    placeholder="数量"
                  />
                </template>
              </template>
            </el-table-column>

            <el-table-column label="人数" align="center" prop="shareableNumber">
              <template slot-scope="scope">
                <template>
                  <el-input-number
                    class="edit-input"
                    v-model="scope.row.shareableNumber"
                    placeholder="人数"
                  />
                </template>
              </template>
            </el-table-column>
            <el-table-column label="单位" align="center" prop="unit" />
          </el-table>
        </el-form>
        <div>
          <el-button @click="cancelForm">取 消</el-button>
          <!-- @click="$refs.drawer.closeDrawer()" -->
          <el-button
            type="primary"
            @click="addFormButton()"
            :loading="loading"
          >{{ loading ? '提交中 ...' : '确 定' }}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  selectcomboByIdApi,
  deletecomboByIdApi,
  editcomboApi,
  addcomboApi,
  listcomboApi
} from "@/api/system/combo/combo.js";

import { listDataProductApi } from "@/api/system/product/product.js";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {
        addDataShow: 1,
        specs: 1
      },
      timer: null,
      ids: null,
      dataColumnList: [{}],
      addDataShow: true,
      radio: "",
      productCodeList: [],
      type: 1
    };
  },
  methods: {
    show(id, type) {
      this.dialog = true;
      this.addDataShow = true;
      this.productList();
      this.type = type;
      if (type == 2) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
        this.dataColumnList = [
          {
            editShow: true
          }
        ];
      }
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.dataColumnList = [];
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      this.addEditForm.comboProductReqVOList = this.dataColumnList;
      if (this.type == 1) {
        addcomboApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
      if (this.type == 2) {
        editcomboApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton(id) {
      selectcomboByIdApi(id).then(res => {
        this.addEditForm = res.result;
        this.dataColumnList = res.result.comboProductReqVOList;
        if (res.result.specs == 1) {
          this.addDataShow = true;
        } else {
          this.addDataShow = false;
        }
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    specsButton(row) {
      if (row == 1) {
        this.addDataShow = true;
        this.dataColumnList = [];
        var entity = {
          editShow:true
        };
        this.dataColumnList.push(entity);
      }
      if (row == 2) {
        this.addDataShow = false;
        this.dataColumnList = [];
      }
    },
    getCurrentRow() {},
    delectButton() {},
    addDataListButton() {
      var entity = {
        editShow: true
      };
      this.dataColumnList.push(entity);
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    productList() {
      listDataProductApi({ attribute: 2,isGround:1 }).then(res => {
        this.productCodeList = res.result;
      });
    },
    selectDisposeMonth(index, rows, attributionMone) {
      console.log(attributionMone);
      rows[index].productCode = attributionMone.productCode;
      rows[index].commodityCode = attributionMone.commodityCode;
      rows[index].commodityName = attributionMone.name;
      rows[index].unit = attributionMone.unit;
      rows[index].uniqueCode = attributionMone.uniqueCode;
    }
  }
};
</script>
<style scoped>
</style>



