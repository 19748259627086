import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonCombo'


const listcomboApi =(params)=>getAction(baseURL+"/list",params);

const dataListComboApi =(params)=>getAction(baseURL+"/dataList",params);

const addcomboApi=(params)=>postAction(baseURL+"/add",params);

const editcomboApi=(params)=>putAction(baseURL+"/edit",params);

const deletecomboByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectcomboByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

export{
    selectcomboByIdApi,
    deletecomboByIdApi,
    editcomboApi,
    addcomboApi,
    listcomboApi,
    dataListComboApi
}
